// JournalsPage.js

import React, { useState, useEffect } from "react";
import "../styles/journals.css";
import { Link } from "react-router-dom";
import axios from "axios";
import TopNavBar from "../components/topNav";
import Footer from "../components/footer";

const JournalsPage = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://dreambox-api.netlify.app/api/fetchProjects"
        );
        setProjects(response.data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <TopNavBar />

      <div className="journal-container">
        <div className="journal-section">
          <h2 className="journal-heading">Portrayals</h2>
          <p className="journal-content">
            They typically involve professionally taken photographs of the bride
            and groom either before, during, or after the wedding ceremony.
            These portraits often showcase the couple's love, emotions, and
            personalities while providing timeless keepsakes to cherish for a
            lifetime.
          </p>
        </div>
      </div>

      <div className="gallery-container">
        <div className="gallery">
          {projects.map((project) => {
            const formattedName = project.project_name.replace(/\s+/g, '-');
            return (
              <Link
                to={`/event/${project.project_id}/${formattedName}`}
                key={project.project_id}
              >
                <div className="jornal-item">
                  <img src={project.thumbnail_url} alt={project.project_name} />
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default JournalsPage;
