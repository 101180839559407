
import React from "react";
import "../styles/insta.css";
import { Button } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import image1 from "../assets/instagram/link 01.jpg";
import image2 from "../assets/instagram/link 02.jpg";
import image3 from "../assets/instagram/link 03.jpg";
import image4 from "../assets/instagram/link 04.jpg";
import image5 from "../assets/instagram/link 05.jpg";

const InstagramPage = () => {
  const imageUrls = [image1, image2, image3, image4, image5];
  const imageClickUrls = [
    "https://www.instagram.com/p/CnpEeyByAUo/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    "https://www.instagram.com/p/C1tY2U1yHWV/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    "https://www.instagram.com/p/CzI_VNILsZJ/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    "https://www.instagram.com/p/CvXTZEYSdfO/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    "https://www.instagram.com/p/C04HkjvSZbF/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
  ];

  const handleFollowClick = () => {
    window.open(
      "https://www.instagram.com/dreambox.photography?igsh=MW5rZDF0bW5lOTk2eg==",
      "_blank"
    );
  };

  const handleImageClick = (index) => {
    const imageUrl = imageClickUrls[index];
    window.open(imageUrl, "_blank");
  };

  return (
    <div className="full-width-container">
      <div className="instagram-follower-page">
        <h1 className="page-heading">INSTAGRAM PHOTOS</h1>
        <div className="follower-images">
          {imageUrls.map((imageUrl, index) => (
            <img
              key={index}
              src={imageUrl}
              alt={`Instagram Ipmage ${index + 1}`}
              onClick={() => handleImageClick(index)}
            />
          ))}
        </div>
        <div className="follow-button">
          <Button
            variant="contained"
            startIcon={<InstagramIcon />}
            onClick={handleFollowClick}
          >
            Follow Us on Instagram
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InstagramPage;
