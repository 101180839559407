/** @format */

import React, { useEffect } from "react";
import "../styles/flimes.css";
import TopNavBar from "../components/topNav";
import Footer from "../components/footer";

const FilmComponent = () => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div>
      <TopNavBar />

      <div className="film-page film-container-film">
        <div className="flim-container">
          <div className="flim-section">
            <h2 className="flim-heading">Films</h2>
            <p className="flim-content">
              A wedding film is a cinematic representation of a couple's special
              day, capturing the emotions, moments, and highlights of their
              wedding. It's more than just a documentation of events; it's an
              artful creation that aims to tell a compelling story, reflecting
              the love, joy, and individuality of the couple.
            </p>
          </div>
        </div>
        {/* Embed YouTube Videos */}
        <div className="content-container-film">
          <div className="video-section-film">
            <iframe
              className="frames"
              title="Video 1"
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/pVExhrbqZSw?si=3lPoNQiBkpjF033-"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
          <div className="text-section-film">
            <h2 className="centered-heading">Prashanth & Priya</h2>
          </div>
        </div>

        {/* Section 2 */}
        <div className="content-container-film">
          <div className="video-section-film">
            <iframe
              className="frames"
              title="Video 2"
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/lTwAoCe3-N4?si=Jc5QLhIO4xIDmbBM"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
          <div className="text-section-film">
            <h2 className="centered-heading">Arun & Tamizh</h2>
            {/* <p>
              The photography project is a creative endeavor focused on capturing moments and emotions through the lens of a camera.
              It involves a diverse range of subjects, from portraits and landscapes to events and abstract compositions.
              The project aims to showcase the artistry and technical skills of the photographer, utilizing various techniques and equipment.
            </p> */}
          </div>
        </div>

        {/* Section 3 */}
        <div className="content-container-film">
          <div className="video-section-film">
            <iframe
              className="frames"
              title="Video 3"
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/-jfJv6XhvIQ?si=yAwxfgKdMwpeZyt4"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
          <div className="text-section-film">
            <h2 className="centered-heading">KrishnaKanth & Shaksi</h2>
          </div>
        </div>

        <div className="content-container-film">
          <div className="video-section-film">
            <iframe
              className="frames"
              title="Video 4"
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/g7A1aE6WD2c?si=EcRevk82paKQZC5C"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
          <div className="text-section-film">
            <h2 className="centered-heading">Tharani & Vimalesh</h2>
          </div>
        </div>

        <div className="content-container-film">
          <div className="video-section-film">
            <iframe
              className="frames"
              title="Video 5"
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/qxjo1pxRjA8?si=NMmCmHM0Pyfm3DJd"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
          <div className="text-section-film">
            <h2 className="centered-heading">Teja & Bantita</h2>
          </div>
        </div>

        <div className="content-container-film">
          <div className="video-section-film">
            <iframe
              className="frames"
              title="Video 6"
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/O9tfZ23ZztY?si=S4Qd3kGJo_jtFPBe"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
          <div className="text-section-film">
            <h2 className="centered-heading">Binu & Muthamad</h2>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FilmComponent;
