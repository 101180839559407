/** @format */

// testiectOne.js

import React, { useEffect } from "react";
import "../styles/testimonial.css";
import { Link } from "react-router-dom";
import TopNavBar from "../components/topNav";
import Footer from "../components/footer";
import test1 from "../assets/testimonial/c 01.jpg";
import test2 from "../assets/testimonial/c 02.jpg";
import test3 from "../assets/testimonial/c 03.jpg";
import test4 from "../assets/testimonial/c 04.jpg";
import test5 from "../assets/testimonial/c 05.jpg";
import test6 from "../assets/testimonial/c 06.jpg";

const allImageUrls = [test1, test2, test3, test4, test5, test6];

const Testimonial = () => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div>
      <TopNavBar />

      <div className="testi-gallery-container">
        <div className="testi-gallery">
          {allImageUrls.map((imageUrl, index) => (
            <Link to="#" key={index} className="testi-gallery-link">
              <img
                src={imageUrl}
                alt={`Photo ${index + 1}`}
                className="testi-gallery-image"
              />
            </Link>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Testimonial;
