/** @format */

import React, { useEffect } from "react";
import "../styles/founder.css";
import TopNavBar from "../components/topNav";
import Footer from "../components/footer";
import founderImage from "../assets/founder/founder.jpg";

const FounderPage = () => {
  document.body.style.backgroundColor = "black";
  document.body.style.color = "white";
  document.body.style.fontFamily = "Mialgor";

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    // document.body.scrollTop = 0;
  }, []);

  return (
    <div>
      <TopNavBar />
      <div className="founder-container">
        <div className="founder-section">
          <h2
            className="founder-heading"
            style={{ color: "#b89043", fontWeight: "bold" }}
          >
            OUR STORY
          </h2>
          <p className="founder-content">
            What began as a pleasant hobby in 2013 bloomed into a burning
            passion in 2014 end.We shot our first wedding in 2015.after a few
            years of diligence.That’s when Dreambox was re-born as a Premium
            Wedding Photography. We are based out of Chennai, but our adventures
            have taken us to some of the most unforgettable locations across
            India. Our creative wedding photography and film ventures are
            carried out according to world-class quality and excellence
            standards. With a high level of professionalism, we are reliable,
            punctual, and organized. If you ask us what’s our best moment, we’d
            always say it is the one next to come. We are visualizers, memory
            shapers, dreamers... we are Dreambox photography.
          </p>
        </div>
      </div>
      <h1 className="master-heading-founder">FOUNDER</h1>
      <div className="header-film-founder">
        <div>
          <img
            src={founderImage}
            alt="Full Page"
            className="full-page-image-founder"
          />
          <div className="image-text-overlay">
            <h2 className="name-founder">Sunil Anandhan</h2>
            <p className="intro-text-journals">
              FOUNDER,MANAGING DIRECTOR,
              <br />
              CINEMATOGRAPHER AND <br /> LEAD PHOTOGRAPER
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FounderPage;
