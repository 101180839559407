/** @format */

import React from "react";
import { Element } from "react-scroll";
import StoryText from "../components/storyof.js";
import "../styles/about.css";

const AboutPage = () => {
  return (
    <div id="about-page">
  <h1 className="master-heading">
    <span className="smaller-text">Why</span> DREAMBOX
  </h1>
  <StoryText />
  <div className="about-container">
    <div className="about-sections">
      <div className="about-section">
        <h2 className="section-heading">Stunning Landscapes</h2>
        <p className="contentAbout">
          Capturing stunning wedding landscapes is an art that goes beyond just
          taking a picture. It's about creating a visual story that not only
          showcases the beauty of the surroundings but also enhances the
          romance and emotions of the moment.
        </p>
      </div>
    </div>
  </div>
</div>
  );
};

export default AboutPage;
