// Project.js
import React, { useState, useEffect } from "react";
import "../../styles/projectsJour.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import TopNavBar from "../topNav";
import Footer from "../footer";

const Project = () => {
  const { projectId } = useParams(); // Retrieve projectId directly from the URL
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(
          `https://dreambox-api.netlify.app/api/fetchImages?project_id=${projectId}`
        );
        setImages(response.data);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, [projectId]);

  return (
    <div>
      <TopNavBar />
      <div className="proj-gallery-container">
        <div className="proj-gallery">
          {images.map((image) => (
            <Link to="#" key={image.image_id} className="proj-gallery-link">
              <img
                src={image.image_url}
                alt={`Photo ${image.image_id}`}
                className="proj-gallery-image"
              />
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Project;
