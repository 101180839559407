import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import "../styles/footer.css";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <hr className="footer-divider" />
        <div className="contact-info">
          <div className="contact-item">
            <PhoneIcon />
            <p>
              <a href="tel:+918870002163">+91 88700 02163</a>
            </p>
          </div>
          <div className="contact-item">
            <MailOutlineIcon />
            <p>
              <a href="mailto:Dreamboxphotoservice@gmail.com">
                dreamboxphotoservice@gmail.com
              </a>
            </p>
          </div>
        </div>
        <div className="copyright-info">
          <p>&copy; 2024 DreamBox. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;