import React from 'react';
import '../styles/newSection.css'; // Import the CSS file for styling

const ResponsiveSection = () => {
  return (
    <div className="responsive-section-container">
      <div className="content-container">
        <h2 className="centered-heading-new">WEDDING FLIM</h2>
        <p className="responsive-story-h1" style={{ color: "#b07346"}}>a visual poem</p>
      </div>
    </div>
  );
};

export default ResponsiveSection;
