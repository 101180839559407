
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/home";
import Testimonials from "./Pages/testimonial";
import FounderPage from "./Pages/founder.js";
import FilmComponent from "./Pages/flimes";
import JournalsPage from "./Pages/journals";
import ProposalPage from "./components/proposal";
import Project from "./components/Journals/project";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/critiques" element={<Testimonials />} />
        <Route path="/ourStory" element={<FounderPage />} />
        <Route path="/films" element={<FilmComponent />} />
        <Route path="/portrayals" element={<JournalsPage />} />
        <Route path="/contactUs" element={<ProposalPage />} />
        <Route path="/event/:projectId/:projectName" element={<Project />} />
      </Routes>
    </Router>
  );
};

export default App;
