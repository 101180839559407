/** @format */

import React from "react";
import "../styles/video.css";

const VideoComponent = () => {
  return (
    <div className="web-video-container">
      <video autoPlay muted loop className="web-local-video">
        <source src={require("../assets/home/video.mp4")} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="web-content">
        <p>
          A wedding film is more than just a video recording of the events that{" "}
          unfold on a couple's special day, it's a cinematic portrayal of their
          love story. It captures the emotions, the details, and the essence of
          the wedding day{" "}
        </p>
      </div>
    </div>
  );
};

export default VideoComponent;
