/** @format */

import React, { useState, useEffect } from "react";
import "../styles/proposal.css";
import TopNavBar from "../components/topNav";
import Footer from "../components/footer";
import headerImage from "../assets/proposal/background.jpg";
import mobileImage from "../assets/proposal/mobile.jpg";

const ProposalPage = () => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    // document.body.scrollTop = 0;
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    coupleName: "",
    eventDate: "",
    eventType: "",
    totalEvents: "",
    elaborateDetails: "",
    thisIsOur: "",
    email: "",
    mobile: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://formspree.io/f/mzbnzvrb", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Form submitted successfully:", formData);
        setFormData({
          name: "",
          coupleName: "",
          eventDate: "",
          eventType: "",
          totalEvents: "",
          elaborateDetails: "",
          thisIsOur: "",
          email: "",
          mobile: "",
        });
      } else {
        console.error("Form submission failed:", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred during form submission:", error);
    }
  };

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <TopNavBar />

      <div
        className={`contact-page film-container-film ${
          isMobileView ? "mobile-view" : ""
        }`}
      >
        <div className="header-film">
          <img
            src={isMobileView ? mobileImage : headerImage}
            alt="Full Page"
            className="full-page-image-film"
            id="headerImage"
          />

          <div className="contact-section">
            <div className="contact-form">
              <p className="contentProposal">
                At this wedding, we aim to create a timeless collection of
                photographs that truly encapsulates the essence of love, joy,
                and celebration.
              </p>
              <form onSubmit={handleSubmit}>
                <div className="label-input-group">
                  <label>Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="label-input-group">
                  <label>Couple Name:</label>
                  <input
                    type="text"
                    name="coupleName"
                    value={formData.coupleName}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="label-input-group">
                  <label>Event Date:</label>
                  <input
                    type="date"
                    name="eventDate"
                    value={formData.eventDate}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="label-input-group">
                  <label>Type of Event:</label>
                  <input
                    type="text"
                    name="eventType"
                    value={formData.eventType}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="label-input-group">
                  <label>Total Number of Events:</label>
                  <input
                    type="number"
                    name="totalEvents"
                    value={formData.totalEvents}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="label-input-group">
                  <label>Elaborate the Event:</label>
                  <textarea
                    name="elaborateDetails"
                    value={formData.elaborateDetails}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="label-input-group">
                  <label>This is Our Budget</label>
                  <textarea
                    name="thisIsOur"
                    value={formData.thisIsOur}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="label-input-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="label-input-group">
                  <label>Mobile:</label>
                  <input
                    type="phone"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                  />
                </div>

                <button type="submit" className="button">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ProposalPage;
