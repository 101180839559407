/** @format */

import React from "react";
import "../styles/newSection.css";

const StoryText = () => {
  return (
    <div className="responsive-story-section-container">
      <p className="responsive-story-content" style={{ color: "#b07346" }}>
        where we capture the timeless moments of your special day with an
        AESTHETIC touch. <br /> With a passion for storytelling through images.
        <br />
        we specialize in the beauty, emotion, and essence of weddings
      </p>
    </div>
  );
};

export default StoryText;
